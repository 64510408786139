import axios from 'axios';
import { serialize, makeFormData } from './form';

declare const window: any;

export default class Map {
    private selector: HTMLElement;

    constructor(selector: string) {
        this.selector = document.querySelector(selector);
        if (this.selector) {
            this.init();
        }
    }

    private init(): void {
        const form: HTMLFormElement = this.selector.querySelector('[data-vote-form]');
        if (form) {
            form.addEventListener('submit', this.vote.bind(this));
        }
    }

    private vote(e: Event): void {
        e.preventDefault();

        const form = e.target as HTMLFormElement;
        const button: HTMLElement = form.querySelector('[data-vote-button]');

        button.classList.add('buttons_button--disabled');

        axios({
            method: 'POST',
            url: form.action,
            data: makeFormData(serialize(form)),
        }).then(() => {
            window.location.reload(true);
        });
    }
}

window.enableVoteButton = () => {
    const form: HTMLFormElement = document.querySelector('.form--vote');
    const button: HTMLFormElement = form.querySelector('[data-vote-button]');
    button.classList.remove('buttons__button--disabled');
};

window.disableVoteButton = () => {
    const form: HTMLFormElement = document.querySelector('.form--vote');
    const button: HTMLFormElement = form.querySelector('[data-vote-button]');
    button.classList.add('buttons__button--disabled');
};
