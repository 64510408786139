// styles
import '../scss/style.scss';

// components
import Map from './components/map';
import Vote from './components/vote';

(() => {
    new Map('#map');
    new Vote('[data-vote-page]');
})();
