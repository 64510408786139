declare const window: any;

export default class Map {
    private selector: HTMLElement;

    constructor(selector: string) {
        this.selector = document.querySelector(selector);
        if (this.selector) {
            this.init();
        }
    }

    private init(): void {
        this.appendScript();
    }

    private appendScript(): void {
        const script: HTMLScriptElement = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${window.key}&callback=init`;
        document.body.appendChild(script);
    }
}

window.init = () => {
    const infowindows = [];
    const markers = [];
    const map = new window.google.maps.Map(document.querySelector('#map'), {
        panControl: false,
        rotateControl: false,
        scaleControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        scrollwheel: false,
        fullscreenControl: false,
        zoomControl: true,
        clickableIcons: false,
        center: {
            lat: window.lat,
            lng: window.lng,
        },
        styles: [
            {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#1087c9',
                    },
                    {
                        lightness: 17,
                    },
                ],
            },
            {
                featureType: 'landscape',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#009ddc',
                    },
                    {
                        lightness: 0,
                    },
                ],
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#7dc5ec',
                    },
                    {
                        lightness: 17,
                    },
                ],
            },
            {
                featureType: 'road',
                elementType: 'geometry.stroke',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'road.highway.controlled_access',
                elementType: 'geometry.stroke',
                stylers: [
                    {
                        color: '#7dc5ec',
                    },
                    {
                        lightness: 0,
                    },
                ],
            },
            {
                featureType: 'road.arterial',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#5fbbe8',
                    },
                    {
                        lightness: 0,
                    },
                ],
            },
            {
                featureType: 'road.local',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#5fbbe8',
                    },
                    {
                        lightness: 0,
                    },
                ],
            },
            {
                featureType: 'poi',
                elementType: 'geometry',
                stylers: [
                    {
                        visibility: 'off',
                    },
                    {
                        lightness: 21,
                    },
                ],
            },
            {
                elementType: 'labels.text.stroke',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        saturation: 0,
                    },
                    {
                        color: '#d7eef9',
                    },
                    {
                        lightness: 0,
                    },
                ],
            },
            {
                elementType: 'labels.icon',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'transit',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#7dc5ec',
                    },
                    {
                        lightness: 19,
                    },
                ],
            },
            {
                featureType: 'administrative',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#7dc5ec',
                    },
                    {
                        lightness: 20,
                    },
                ],
            },
            {
                featureType: 'administrative',
                elementType: 'geometry.stroke',
                stylers: [
                    {
                        color: '#7dc5ec',
                    },
                    {
                        lightness: 17,
                    },
                    {
                        weight: 1.2,
                    },
                ],
            },
        ],
        zoom: window.zoom,
    });

    window.pins.forEach((pin) => {
        const marker = new window.google.maps.Marker({
            map,
            draggable: false,
            position: new window.google.maps.LatLng(pin.lat, pin.lng),
            icon: {
                url: window.icon,
                scaledSize: new window.google.maps.Size(30, 30),
            },
        });

        const infowindow = new window.google.maps.InfoWindow({
            content: `<span class="map__icon-text">${pin.text}</span>`,
        });

        infowindows.push(infowindow);
        markers.push(marker);

        marker.addListener('click', () => {
            infowindows.forEach((item) => {
                item.close();
            });
            infowindow.open(map, marker);
        });

        map.addListener('click', () => {
            infowindow.close();
        });
    });
};
