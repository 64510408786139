export function serialize(form: HTMLFormElement) {
    const data: any = {};
    const inputs: NodeListOf<HTMLInputElement> = form.querySelectorAll('input, select, textarea');

    inputs.forEach((input: HTMLInputElement) => {
        if (!input.classList.contains('no-serialize')) {
            data[input.name] = input.value;
        }
    });

    return data;
}

export function makeFormData(object: any) {
    const data: FormData = new FormData();

    Object.keys(object).map((key) => {
        data.append(key, object[key]);
    });

    return data;
}
